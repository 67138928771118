import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

// Guards
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['account/sign-in']);
const redirectLoggedInToMainPage = () => redirectLoggedInTo(['/']);

/**
 * Defines the routes for the application.
 */
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/shell/shell.module').then((m) => m.ShellModule),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Account',
      authGuardPipe: redirectLoggedInToMainPage,
    },
  },
  {
    path: 'payment/:clientSecret',
    loadChildren: () => import('./pages/payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Payment',
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'restricted',
    loadChildren: () =>
      import('./pages/restricted-content/restricted-content.module').then((m) => m.RestrictedContentModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
