import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ProviderRepository } from '@src/app/pages/shell/provider/provider.repository';
import { Observable } from 'rxjs';

@Injectable()
export class AddProviderIdInterceptor implements HttpInterceptor {
  readonly providerRepository = inject(ProviderRepository);
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
    providerRepository = inject(ProviderRepository),
  ): Observable<HttpEvent<any>> {
    const providerId = providerRepository.currentProvider?._id;

    if (providerId) {
      const url = request.url.replace(/:providerId\d+/, `${providerId}`);
      request = request.clone({ url });
    }

    return next.handle(request);
  }
}
